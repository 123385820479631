import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import { COLORS } from "../../../SupportingFiles/colors";
import "./MHome.scss";
import { FRONTEND_DOMAIN } from "../../../Routing/CommonURLs/Domain";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";

function MHome() {
  const [value, setValue] = React.useState("2");
  const [comingSoon, setComingSoon] = useState(true);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const headerSlidersettings = {
    className: "center",
    // centerMode: true,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    // cssEase: "linear",
    arrows: false,
    dots: true,
  };

  const headerSliderArray = [
    {
      sliderBgclass: "health-sb",
      slider_img_icon: "./images/health-slider-icon.svg",
      productImg: "./images/health-sliderbox-sm.png",
      INS_Type_tag: "Health Insurance",
      tagline: "Best Health Insurance that suits you !",
      buttonText: "View Health Plan",
      comingSoonStatus: false,
    },
    {
      sliderBgclass: "car-sb",
      slider_img_icon: "./images/car-slider-icon.svg",
      productImg: "./images/car-sliderbox.png",
      INS_Type_tag: "Car Insurance",
      tagline: "Buy Car Insurance in 5 Minutes !",
      buttonText: "View Car Plan",
      comingSoonStatus: false,
    },
    // {
    //   sliderBgclass: "car-sb",
    //   slider_img_icon: "./images/car-slider-icon.svg",
    //   productImg: "./images/car-sliderbox.png",
    //   INS_Type_tag: "Car Insurance",
    //   tagline: "Buy Car Insurance in 5 Minutes !",
    //   buttonText: "View Car Plan",
    // },
    {
      sliderBgclass: "term-sb",
      slider_img_icon: "./images/term-slider-icon.svg",
      productImg: "./images/term-sliderbox.png",
      INS_Type_tag: "Term Insurance",
      tagline: "Find the right term plan for you !",
      buttonText: "Coming Soon...",
      comingSoonStatus: true,
    },
  ];

  const productSlidersettings = {
    className: "center",
    // centerMode: true,
    infinite: true,
    slidesToShow: 1,
    autoplay: false,
    speed: 2000,
    autoplaySpeed: 4000,
    // cssEase: "linear",
    arrows: true,
    dots: false,
  };

  const productSliderArray = [
    {
      sliderBgclass: "health-sb",
      INS_Type_tag: "Health Insurance",
      tagline: (
        <>
          Best Health Insurance Plan Online, Health Plans Starting at{" "}
          <span>₹ 17/- per day*</span>
        </>
      ),
      buttontxt: "View Health Plan",
    },
    //
    // {
    //   sliderBgclass: "term-sb",
    //   INS_Type_tag: "Term Insurance",
    //   tagline: (
    //     <>
    //       Get <span>1 Crore Life Cover</span> at Just <span>₹ 678/-Month*</span>
    //     </>
    //   ),
    //   buttontxt: "View Term Plan",
    // },
    {
      sliderBgclass: "car-sb",
      INS_Type_tag: "Car Insurance",
      tagline: (
        <>
          Car Insurance Starting from <span>₹ 2855*</span>
        </>
      ),
      buttontxt: "View Car Plan",
    },
    {
      sliderBgclass: "bike-sb",
      INS_Type_tag: "Bike Insurance",
      tagline: (
        <>
          Bike Insurance Starting from <span>₹ 1167*</span>
        </>
      ),
      buttontxt: "View Bike Plan",
    },
  ];

  const testimonialSlidersettings = {
    className: "center",
    // centerMode: true,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: true,
    dots: false,
  };

  const testimonialArray = [
    {
      feedback:
        "While I wanted to invest in a Pension Plan , I was completely unaware of how should I begin. At Muthoot Insurance Brokers Pvt Ltd , not only did I find a number of options, but their consultancy services helped me understand my needs better allowing me to choose the right plan",
      userName: "Satya Anand",
      designation: "Business Entrepreneur",
    },
    {
      feedback:
        "Claim redressal has been a pain point for me with many previous insurance Solutions Providers , At Muthoot Insurance Brokers , however, I found not just the best insurance policy for my vehicle but also got an easy claim redressal process and hassle-free correspondence with the company. Their process is well streamlined, and claims are systematically sanctioned on time",
      userName: "Sneha Goyal",
      designation: "Teacher Teaching Professional",
    },
    {
      feedback:
        "Right from the initial stage of searching through insurance plans to applying for and receiving claims , It is super convenient to get in touch with the representatives of Muthoot Insurance Brokers Pvt Ltd. Their response time and quality of services are unmatched , making them the best insurance broking company in India.",
      userName: "Rucha Sakya",
      designation: "Software Professional",
    },
    {
      feedback:
        "For me, Muthoot is definitely a one-stop insurance solution provider. From property to health, I found the best Insurance plans to secure my family’s future. The best part about Muthoot insurance brokers is the promptness of their representatives, which makes the entire process quick and easy.",
      userName: "Santosh Saxena",
      designation: "Business Entrepreneur",
    },
    {
      feedback:
        "If you are looking for insurance solutions, Muthoot is the best choice for you. The complete range of insurance plans offered by them is customized to your needs, further providing maximum benefits and all-round protection.",
      userName: "Juhi Saini",
      designation: "Teacher Teaching Professional",
    },
    {
      feedback:
        "We wanted to get a home insurance policy for our new home when Muthoot Insurance Brokers Pvt. Ltd. was suggested to us. The policy had a competitive premium and offered a wide choice in sum insurance coverage. The best part is that the policy was issued almost immediately.",
      userName: "Rajneesh Shahi",
      designation: "Software Professional",
    },
  ];

  return (
    <Box className="mhomepage-wrapper">
      <MNavBar />

      <Box className="mproduct-section" marginBottom={"40px"}>
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} padding={0} marginBottom={"36px"}>
            <Slider {...headerSlidersettings}>
              {headerSliderArray?.map((data, index) => (
                <Box padding="0px 12px">
                  <Box className={`sliderBox ${data.sliderBgclass}`}>
                    <Box minWidth={"180px"} flex="1">
                      <span>
                        {data.INS_Type_tag}
                        <img src={data.slider_img_icon} alt="" />
                      </span>
                      <h4>{data.tagline}</h4>
                      {comingSoon === data.comingSoonStatus ? (
                        <h5 style={{ color: COLORS.white }}>
                          {data.buttonText}
                        </h5>
                      ) : (
                        <CustomButton
                          className="primaryBtn small mb-2"
                          text={data.buttonText}
                          onClick={() => ({})}
                          fullWidth={false}
                          variant="contained"
                        />
                      )}
                    </Box>
                    <Box
                      display="flex"
                      alignSelf="end"
                      flex="1"
                      height="186px"
                      sx={{
                        "@media(max-width:499px)": {
                          height: "150px",
                        },
                      }}>
                      <img
                        src={data.productImg}
                        height="100%"
                        alt="img"
                        className="ab-img"
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
            </Slider>
          </Grid>
          <Grid xs={12}>
            <Box display={"flex"} gap={"12px"} flexWrap={"wrap"}>
              <Box
                className="ip_box"
                onClick={() => {
                  window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.CAR_FORM}`;
                }}>
                <img src="./images/car_product_icon.svg" alt="" />
                <p>
                  Car <br /> Insurance
                </p>
              </Box>
              <Box
                className="ip_box"
                onClick={() => {
                  window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.TW_FORM}`;
                }}>
                <img src="./images/tw_product_icon.svg" alt="" />
                <p>
                  Two Wheeler <br /> Insurance
                </p>
              </Box>
              <Box
                className="ip_box "
                onClick={() => {
                  window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.HEALTH_FORM}`;
                }}>
                <img src="./images/health_product_icon.svg" alt="" />
                <p>
                  Health <br /> Insurance
                </p>
              </Box>
              <Box
                className="ip_box commingSoon"
                onClick={() => {
                  // window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.TERM_FORM}`;
                }}>
                <img src="./images/term_product_icon.svg" alt="" />
                <p>
                  Term <br /> Insurance
                </p>
              </Box>
              <Box className="ip_box commingSoon">
                <img src="./images/investment_product_icon.svg" alt="" />
                <p>
                  Investment <br /> Plans
                </p>
              </Box>
              <Box className="ip_box commingSoon">
                <img src="./images/savingPlans_product_icon.svg" alt="" />
                <p>
                  Savings <br /> Plans
                </p>
              </Box>
              <Box className="ip_box commingSoon">
                <img src="./images/pensionAnnuity_product_icon.svg" alt="" />
                <p>
                  Pension/ <br /> Annuity Plans
                </p>
              </Box>
              <Box className="ip_box commingSoon">
                <img src="./images/homeInsurance_product_icon.svg" alt="" />
                <p>
                  Home <br /> Insurance
                </p>
              </Box>
              {/* <Box className="ip_box">
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  height={"100%"}
                >
                  <span style={{ color: COLORS.primary }}>
                    View all
                    <br /> products
                  </span>
                </Box>
              </Box> */}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="mredbg-section" marginBottom={"40px"}>
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Box className="redbox">
              <Box textAlign={"center"} marginBottom={"24px"}>
                <img src="./images/redbox-person_calling_img.svg" alt="" />
              </Box>
              <h5>
                <span>We are present both</span> online and offline
                <span> to serve, </span>21 years
                <span> of insurance intermediation</span>
              </h5>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="mabout-section" marginBottom={"40px"}>
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Box className="aboutbg_box">
              <Box
                textAlign={"center"}
                marginBottom={"24px"}
                paddingTop={"24px"}>
                <img src="./images/aboutSec-img.svg" alt="" />
              </Box>
              <Box>
                <h4 className="mb-3">
                  <span>About</span> Muthoot Insurance
                  <span> Brokers</span>
                </h4>
                <p className="mb-3">
                  Muthoot Insurance Brokers Private Limited, founded in 2002, is
                  an IRDAI-licensed insurance broking division of Muthoot
                  Finance. We are one of the leading insurance brokers
                  undertaking direct insurance broking in Life and Non - Life
                  businesses in India.
                </p>
                <CustomButton
                  className={"primaryBtn"}
                  text="Read More"
                  fullWidth={false}
                  variant={"contained"}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="mwhy-choose" marginBottom={"40px"}>
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12}>
            <Box className="wc_box">
              <h4 className="mb-2">
                <span>Why</span> customers <span>should</span> choose Muthoot
                Insurance Brokers <span> to buy insurance?</span>
              </h4>
              <Box textAlign={"center"}>
                <img
                  src="./images/why_choose_sec_img.svg"
                  alt=""
                  className="wc_sec_img"
                />
              </Box>
              <ul className="wc_points">
                <li>
                  <img src="./images/wc_icon1.svg" alt="" />
                  <Box textAlign={"center"}>
                    <h6>PhysiDigital</h6>
                    <p>
                      We have both Physical and Digital presence to serve you
                      better.
                    </p>
                  </Box>
                </li>
                <li>
                  <img src="./images/wc_icon2.svg" alt="" />
                  <Box textAlign={"center"}>
                    <h6>Claims</h6>
                    <p>
                      Excellent claims department to assist you in Claim Process
                    </p>
                  </Box>
                </li>
                <li>
                  <img src="./images/wc_icon3.svg" alt="" />
                  <Box textAlign={"center"}>
                    <h6>Best Products</h6>
                    <p>
                      Best products available and excellent after sales service
                    </p>
                  </Box>
                </li>
                <li>
                  <img src="./images/wc_icon4.svg" alt="" />
                  <Box textAlign={"center"}>
                    <h6>PhysiDigital</h6>
                    <p>Unbiased support from our experts.</p>
                  </Box>
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="mbuyingIns-sec_outer" marginBottom={"40px"}>
        <h4
          style={{
            textAlign: "center",
            padding: "0 12px",
            marginBottom: "24px",
          }}>
          <span> Buying </span>Insurance Made Easy
          <span> with Three steps.</span>
        </h4>
        <Box className="mbuyingIns-sec">
          <Grid
            container
            columnSpacing={3}
            className="row"
            alignItems={"center"}>
            <Grid xs={12}>
              <ul>
                <li>
                  <img src="./images/bi_sec_img1.svg" alt="" />
                  <Box>
                    <h6>Choose Product</h6>
                    <p>Based on your needs, Select the product.</p>
                  </Box>
                </li>
                <li>
                  <img src="./images/bi_sec_img2.svg" alt="" />
                  <Box>
                    <h6>Fill Details</h6>
                    <p>
                      Fill your details and get the insurance policy premium
                      quotes with in seconds.
                    </p>
                  </Box>
                </li>
                <li>
                  <img src="./images/bi_sec_img3.svg" alt="" />
                  <Box>
                    <h6>Make Payment</h6>
                    <p>Select the payment option and complete the payment</p>
                  </Box>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className="mproduct-slider" marginBottom={"70px"}>
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} padding={"0"}>
            <Slider {...productSlidersettings}>
              {productSliderArray?.map((data, index) => (
                <Box padding={"0 12px"}>
                  <Box
                    className={`sliderBox ${data.sliderBgclass}`}
                    padding={"12px"}>
                    <Box maxWidth={"185px"}>
                      <span className="ins_tag">{data.INS_Type_tag}</span>
                      <p>{data.tagline}</p>
                      <CustomButton
                        className="primaryBtn small mb-2"
                        text={data.buttontxt}
                        onClick={() => ({})}
                        fullWidth={false}
                        variant="contained"
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
            </Slider>
          </Grid>
        </Grid>
      </Box>

      <Box className="msolution-section" marginBottom={"40px"}>
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
            <h4 className="mb-2">
              Solution <span> for all your </span>Insurance
              <span> Needs</span>
            </h4>
            <p>
              We offer customized products and personalized service from Quote
              Generation till after sales service
            </p>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} textAlign={"center"}>
            <ul>
              <li>
                <img src="./images/sol-sec-icon1.svg" alt="" />
                <p>Policy Wallet - All your policies in one place</p>
              </li>
              <li>
                <img src="./images/sol-sec-icon2.svg" alt="" />
                <p>Insurance Quotes from multiple insurers</p>
              </li>
              <li>
                <img src="./images/sol-sec-icon3.svg" alt="" />
                <p>Application Assistance</p>
              </li>
              <li>
                <img src="./images/sol-sec-icon4.svg" alt="" />
                <p>Issuance Support</p>
              </li>
              <li>
                <img src="./images/sol-sec-icon5.svg" alt="" />
                <p>Claim Assistance</p>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="mtestimonial-section" marginBottom={"80px"}>
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
            <h4>
              <span> What Our </span>Customers Say
              <span> About Us</span>
            </h4>
          </Grid>
          <Grid xs={12} padding={"0"}>
            <Slider {...testimonialSlidersettings}>
              {testimonialArray.map((data: any, index: number) => (
                <Box padding={"0 12px"}>
                  <Box className="testimonial-box">
                    <Box className="innerBox">
                      <p className="mb-3">{data.feedback}</p>
                      <p style={{ fontWeight: 500, marginBottom: "4px" }}>
                        {data.userName}
                      </p>
                      <p style={{ fontSize: "12px", lineHeight: "18px" }}>
                        {data.designation}
                      </p>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Slider>
          </Grid>
        </Grid>
      </Box>

      <Box className="msatisfied-customer-sec" marginBottom={"40px"}>
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} textAlign={"center"}>
            <h4 className="mb-3 ">
              <span>Join Millions who trust</span> Muthoot Insurance Brokers
            </h4>
          </Grid>
          <Grid xs={12}>
            <ul className="sc_box">
              <li>
                <Box>
                  <img src="./images/sc_icon1.svg" alt="" />
                </Box>
                <Box>
                  <h6>32 lakhs + </h6>
                  <p>Satisfied Customers FY 23/24</p>
                </Box>
              </li>
              <li>
                <Box>
                  <img src="./images/sc_icon2.svg" alt="" />
                </Box>
                <Box>
                  <h6>35 lakhs + </h6>
                  <p>Policies Issued FY 23/24</p>
                </Box>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="mpartners-section" marginBottom={"40px"}>
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} marginBottom={"16px"} textAlign={"center"}>
            <h4>
              <span> Get Leading </span>Insurer's best plans
              <span> in a minute</span>
            </h4>
          </Grid>
          <Grid xs={12} className={"partnerTabs"}>
            <TabContext value={value}>
              <Box>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example">
                  {/* <Tab label="Health" value="1" /> */}
                  <Tab label="Bike" value="2" />
                  <Tab label="Car" value="3" />
                  {/* <Tab label="Term" value="4" /> */}
                </TabList>
              </Box>
              <Box>
                <TabPanel value="1">
                  <ul>
                    <li>
                      <img
                        src="./images/insurers/Max-Bupa-Health-Insurance-Company-Ltd.svg"
                        alt=""
                      />
                    </li>
                    <li>
                      <img
                        src="./images/insurers/Religare-Health-Insurance-Company-Limited.svg"
                        alt=""
                      />
                    </li>
                    <li>
                      <img src="./images/insurers/Bajaj_Alianz_2.svg" alt="" />
                    </li>
                  </ul>
                </TabPanel>
                <TabPanel value="2">
                  <ul>
                    <li>
                      <img
                        src="./images/insurers/ICICI-Lombard-General-Insurance-Co-Ltd.svg"
                        alt=""
                      />
                    </li>
                    <li>
                      <img
                        src="./images/insurers/HDFC-ERGO-General-Insurance-Co-Ltd.svg"
                        alt=""
                      />
                    </li>
                    <li>
                      <img src="./images/insurers/bajaj_logo.svg" alt="" />
                    </li>
                  </ul>
                </TabPanel>
                <TabPanel value="3">
                  <ul>
                    <li>
                      <img
                        src="./images/insurers/ICICI-Lombard-General-Insurance-Co-Ltd.svg"
                        alt=""
                      />
                    </li>
                    <li>
                      <img
                        src="./images/insurers/HDFC-ERGO-General-Insurance-Co-Ltd.svg"
                        alt=""
                      />
                    </li>
                    <li>
                      <img src="./images/insurers/bajaj_logo.svg" alt="" />
                    </li>
                  </ul>
                </TabPanel>
                <TabPanel value="4">
                  <ul>
                    <li>
                      <img
                        src="./images/insurers/ICICI-Prudential-Life-Insurance-Co-Ltd.svg"
                        alt=""
                      />
                    </li>
                    <li>
                      <img
                        src="./images/insurers/HDFC-Standard-Life-Insurance-Co-Ltd.svg"
                        alt=""
                      />
                    </li>
                    <li>
                      <img
                        src="./images/insurers/Bajaj-Allianz-Life-Insurance-Company-Limited.svg"
                        alt=""
                      />
                    </li>
                  </ul>
                </TabPanel>
              </Box>
            </TabContext>
          </Grid>
        </Grid>
      </Box>

      <MFooter />
    </Box>
  );
}

export default MHome;
